<template>
  <div>
    <div class="programDetails_header programDetails_headers">
      <div class="programDetails_headerIcon" @click="goBack()">
        <i class="el-icon-back"></i>
      </div>
      <div>{{value}}</div>
      <div class="programDetails_headerIcon" @click="goHome()">
        <i class="el-icon-house"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['value'],
  data() {
    return {};
  },
  methods: {
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    // 首页
    goHome() {
      this.$router.push({
        path: "/mHome",
      });
    },
  },
};
</script>

<style scoped>
/* 顶部栏 */
.programDetails_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem;
  color: #000;
  font-size: 14px;
  background: #fdd30f;
}
.programDetails_headers {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  z-index: 999;
}
.programDetails_header i {
  font-weight: 600;
}
.programDetails_headerIcon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 100%;
  background-color: #ffffff63;
}
</style>