<template>
<transition name="el-zoom-in-center">
  <div class="dialog_bg" draggable="true" v-show="visible">
    <div class="dialog_box">
      <div class="box_title">上传素材</div>
      <div class="close_btn" @click.stop="close">
        <i class="el-icon-close"></i>
      </div>
      <!-- 拖拽上传区域 -->
      <div class="box_inner" @drop.prevent="onDrop" @dragover.prevent="onDragover" @dragleave.prevent="dragover = false">
        <!-- 选择画板 -->
        <div class="draw_name" v-if="fileList.length > 0">
          <div class="current" @click.stop="chooseDraw" v-if="!showDrawList">
            <div class="name">{{drawList[drawIndex ? drawIndex : drawList.length - 1].name}}</div>
            <div class="arrow">
              <i class="iconfont iconsanjiaoxing"></i>
            </div>
          </div>
          <div class="draw_list" v-if="showDrawList">
            <div class="">
              <div class="select">
                <div class="side_title"><i class="iconfont iconlishi"></i></div>
                <div class="options">
                  <div class="option" v-for="(item, index) in drawList" :key="index" @click.stop="comfirmDraw(index)">{{item.name}}</div>
                </div>
              </div>
              <div class="quick_add">
                <input type="drawName" placeholder="快速创建画板">
              </div>
            </div>
          </div>
        </div>
        <!-- 上传文件列表 -->
        <div class="upload_list">
          <div class="file clearfix" v-for="(file, index) in fileList" :key="index">
            <div class="preview">
              <img :src="file.url" v-if="file.url">
            </div>
            <div class="content">
              <textarea v-model="file.desc" class="description" placeholder="填写作品的相关描述"></textarea>
            </div>
            <div class="uploading" v-if="file.status == 'uploading'">
              <div class="progress" :style="{ width: file.progress }"></div>
              <div class="uploading_text">上传中<i class="el-icon-loading"></i> {{file.progress}}</div>
            </div>
            <div class="fail" v-if="file.status == 'fail'">
              <div class="fail_note">图片上传失败</div>
            </div>
            <div class="remove" @click.stop="delFile(file.uid)">
              <i class="el-icon-circle-close"></i>
            </div>
          </div>
        </div>
        <div class="upload_area" :class="[dragover ? 'active' : '', fileList.length > 0 ? 'has_data' : '']">
          <div class="normal" v-if="fileList.length == 0">
            <label class="title">拖动图片到本区域上传</label>
            <label class="click_info">
              或者
              <span class="brown_link">点击这里</span>
              上传
            </label>
            <span class="tips">每次最多上传 {{limit}} 张，单张文件体积不超过 {{size >= 1 ? size+'MB' : parseInt(size*1024)+'KB'}}</span>
          </div>
          <div class="uploaded" v-if="fileList.length > 0">
            <label class="title">
              拖动文件到本窗口以上传，或者
            </label>
            <label class="click_info">
              <span class="brown_link">点击这里</span>
            </label>
            <div class="upload_text">
              已选择 {{fileList.length}} 张，还可以选择 {{limit - fileList.length}} 张
            </div>
          </div>
          <input class="upload_input" type="file" :accept="accept" multiple title="" @change='handleUploadChange($event)' ref="upload_input">
        </div>
        <!-- 拖拽提示 -->
        <div class="fullscreen" :class="[dragover ? 'pointer_events' : '']" v-show="dragover">
          <div class="fullscreen_text">释放鼠标以上传</div>
        </div>
      </div>
      <!-- 操作按钮 -->
      <div class="box_bottom">
        <div class="buttons">
          <el-button type="info" plain @click.stop="close">取消</el-button>
          <el-button type="danger" :icon="uploading ? 'el-icon-loading' : ''" :disabled="uploading" @click.stop="submit">
            {{uploading ? '上传中' : '上传'}}
          </el-button>
        </div>
      </div>   
    </div>
  </div>
</transition>
</template>

<script>
export default {
  name: 'UploadImage',
  props: {
    visible: Boolean
  },
  data() {
    return {
      accept: 'image/*',
      size: 10,
      limit: 10,
      dragover: false,
      fileList: [], // 上传列表
      pending: [], // 请求标识存储
      drawList: [{ id: '1', name: '平面' },{ id: '2', name: '二刺猿' },{ id: '3', name: '游戏' },{ id: '4', name: '生活艺术' },],
      drawIndex: '',
      drawName: '',
      showDrawList: false,
      uploading: false
    }
  },
  methods: {
    async handleUploadChange(e) { // 点击选择文件
      this.$message('接口暂未开通');
      return false;
      let list = [], noPass = [];
      const accept = this.accept;
      if (!accept) {
        list = [].slice.call(e.target.files);
      } else {
        list = [].slice.call(e.target.files).filter(file => {
          const { type, name, size } = file;
          // 检验大小
          if (size > this.size * 1024 * 1024) {
            noPass.push(name);
            return false;
          }
          // 检验类型
          const extension = name.indexOf('.') > -1 ? `.${ name.split('.').pop() }` : '';
          const baseType = type.replace(/\/.*$/, '');
          return accept.split(',')
            .map(type => type.trim())
            // .filter(type => type)
            .some(acceptedType => {
              if (/\..+$/.test(acceptedType)) {
                return extension === acceptedType;
              }
              if (/\/\*$/.test(acceptedType)) {
                return baseType === acceptedType.replace(/\/\*$/, '');
              }
              if (/^[^\/]+\/[^\/]+$/.test(acceptedType)) {
                return type === acceptedType;
              }
              return false;
            });
        });
      }
      if (noPass.length > 0) {
        this.$message({
          message: `${noPass.join(', ')} 超过限制`,
          duration: 0,
          showClose: true,
          type: 'warning'
        });
      }
      list.forEach(file => {
        if (this.fileList.length < this.limit) {
          let currentTime = Date.now();
          this.fileList.push({
            uid: currentTime + '-' + file.name,
            file: file,
            progress: '0%',
            uploaded: false,
            status: 'ready',
            name: file.name,
            url: '',
            content: ''
          });
        }
      });
      this.$refs.upload_input.value = null;
      this.upload();
　　},
    async onDrop(e) { // 放下拖动的文件
      this.dragover = false;
      this.$message('接口暂未开通');
      return false;
      const accept = this.accept;
      let list = [], noPass = [];
      if (!accept) {
        list = [].slice.call(e.dataTransfer.files);
      } else {
        list = [].slice.call(e.dataTransfer.files).filter(file => { // 建议文件类型
          const { type, name, size } = file;
          if (size > this.size * 1024 * 1024) {
            noPass.push(name);
            return false;
          }
          const extension = name.indexOf('.') > -1 ? `.${ name.split('.').pop() }` : '';
          const baseType = type.replace(/\/.*$/, '');
          return accept.split(',')
            .map(type => type.trim())
            // .filter(type => type)
            .some(acceptedType => {
              if (/\..+$/.test(acceptedType)) {
                return extension === acceptedType;
              }
              if (/\/\*$/.test(acceptedType)) {
                return baseType === acceptedType.replace(/\/\*$/, '');
              }
              if (/^[^\/]+\/[^\/]+$/.test(acceptedType)) {
                return type === acceptedType;
              }
              return false;
            });
        });
      }
      if (noPass.length > 0) {
        this.$message({
          message: `${noPass.join(', ')} 超过限制`,
          duration: 0,
          showClose: true,
          type: 'warning'
        });
      }
      list.forEach(file => {
        if (this.fileList.length < this.limit) {
          let currentTime = Date.now();
          this.fileList.push({
            uid: currentTime + '-' + file.name,
            file: file,
            progress: '0%',
            uploaded: false,
            status: 'ready',
            name: file.name,
            url: '',
            content: ''
          });
        }
      });
      this.upload();
    },
    onDragover() {  // 拖动文件进入可放置区域
      if (!this.dragover) {
        this.dragover = true;
      }
    },
    upload() { // 上传图片
      let that = this;
      let CancelToken = that.$axios.CancelToken;
      for (let file of that.fileList) {
        if (!file.uploaded) {
          file.uploaded = true;
          file.status = 'uploading';
          let formData = new FormData();
          formData.append('file', file.file);
          that.$service.UPLOAD({
            method: 'post',
            data: formData,
            onUploadProgress(progressEvent) {
              let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%';
              if (file && complete) file.progress = complete;
            },
            cancelToken: new CancelToken(function executor(c) {
              that.pending.push({
                uid: file.uid,
                f: c
              })
            })
          }).then(res => {
            if (typeof res.data === 'string') {
              file.status = 'fail';
            } else {
              file.status = 'success';
              file.url = res.url;
            }
          }).catch(err => {
            if (err) {
              file.status = 'fail';
            }
          });
        }
      }
    },
    cancelUpload(uid) { // 取消上传
      for(let i = 0; i < this.pending.length; i++) {
        if (this.pending[i].uid == uid) {
          this.pending[i].f();
        }
      }
    },
    delFile(uid) {  // 删除文件
      this.cancelUpload(uid);
      let idx;
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].uid == uid) {
          idx = i;
        }
      }
      this.fileList.splice(idx, 1);
    },
    close() { // 关闭
      this.fileList.splice(0, this.fileList.length);
      this.pending = [];
      this.classifyName = '';
      this.$emit('update:visible', false);
    },
    submit() {  // 提交素材
      this.$message('接口开发中');
      return false;
      this.uploading = true;
      setTimeout(() => {
        this.uploading = false;
      }, 3000)
    },
    chooseDraw() {  // 选择画板
      this.showDrawList = true;
    },
    comfirmDraw(index) {
      this.drawIndex = index;
      this.showDrawList = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog_bg {
  background: rgba(0,0,0,.3);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  .dialog_box {
    width: 580px;
    box-shadow: 0 2px 5px rgba(0,0,0,.3);
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .box_title {
      line-height: 50px;
      font-size: 16px;
      border-bottom: 1px solid #ededed;
      padding-left: 30px;
      box-sizing: border-box;
      background: #f5f5f5;
      color: #222;
    }
    .box_inner {
      box-sizing: border-box;
      position: relative;
      padding: 20px 30px;
      background: #fff;
      .draw_name {
        padding-top: 4px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ededed;
        position: relative;
        .current {
          display: block;
          position: relative;
          background-color: #f0eded;
          background: linear-gradient(#fafafa,#f2f2f2);
          cursor: pointer;
          border: 1px solid #ccc;
          font-size: 16px;
          border-radius: 3px;
          box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
          box-sizing: border-box;
          width: 100%;
          .name {
            padding: 7px 24px 7px 12px;
            height: 22px;
            line-height: 22px;
            overflow: hidden;
            white-space: nowrap;
          }
          .arrow {
            position: absolute;
            top: 8px;
            right: 14px;
          }
        }
        .draw_list {
          position: absolute;
          z-index: 9999;
          top: -1px;
          left: -1px;
          overflow-x: hidden;
          width: 100%;
          border: 1px solid #bfbfbf;
          box-shadow: inset 0 1px 1px rgba(0,0,0,.1);
          border-radius: 3px;
          font-size: 16px;
          background: #fff;
          .select {
            max-height: 210px;
            overflow: auto;
            position: relative;
            .side_title {
              position: absolute;
              left: 0;
              top: 0;
              width: 35px;
              height: 100%;
              text-align: center;
              padding-top: 10px;
              > i {
                font-size: 20px;
              }
            }
            .options {
              margin-left: 35px;
              background: #fff;
              border-left: 1px solid #ebebeb;
              .option {
                display: block;
                height: 30px;
                line-height: 30px;
                color: #524d4d;
                cursor: pointer;
                text-decoration: none;
                white-space: nowrap;
                overflow: hidden;
                padding-left: 8px;
                &:hover {
                  color: #221919;
                  background: #f4f4f4;
                }
              }
            }
          }
          .quick_add {
          background: #fafafa;
          border-top: 1px solid #ebebeb;
          border-radius: 0 0 3px 3px;
          cursor: default;
          padding: 8px;
          position: relative;
          input {
            display: inline-block;
            padding: 0 10px;
            height: 38px;
            font-size: 16px;
            line-height: 1;
            color: #777;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 2px;
            width: 250px;
            box-shadow: inset 0 1px 2px rgba(0,0,0,.05);
            outline: 0;
            width: 100%;
            box-sizing: border-box;
          }
        }
        }
        
      }
      .upload_list {
        position: relative;
        max-height: 320px;
        overflow-y: auto;
        .file {
          background-color: #fafafa;
          border: 1px solid #ededed;
          padding: 10px 12px;
          margin-bottom: 10px;
          width: 100%;
          box-sizing: border-box;
          position: relative;
          &:hover {
            .remove {
              display: block;
            }
          }
          .preview {
            float: left;
            width: 72px;
            height: 72px;
            overflow: hidden;
            > img {
              width: 100%;
            }
          }
          .content {
            float: left;
            margin-left: 10px;
            padding-top: 6px;
            padding-bottom: 6px;
            .description {
              display: inline-block;
              padding: 6px 10px;
              color: #221919;
              background: #fff;
              border: 1px solid #ccc;
              box-shadow: inset 0 1px rgba(34,25,25,.15), 0 1px rgba(255,255,255,.8);
              width: 350px;
              height: 45px;
              border-color: #ccc;
              resize: none;
              outline: 0;
            }
          }
          .remove {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            z-index: 1;
            > i {
              position: absolute;
              right: 0;
              top: 0;
              font-size: 30px;
              color: #ed1941;
            }
          }
          .uploading {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.3);
            .progress {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background: rgba(0,0,0,.35);
            }
            .uploading_text {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
            }
          }
          .fail {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.3);
            .fail_note {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
            }
          }
        }
      }
      .upload_area {
        border: 2px dashed #ededed;
        height: 206px;
        text-align: center;
        position: relative;
        margin-top: 10px;
        &.active {
          border-color: #bbb;
          background: #f2f2f2;
          background-clip: content-box;
        }
        &.has_data {
          height: 88px;
          overflow: hidden;
        }
        .upload_input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.3);
          opacity: 0;
          outline: 0;
        }
        .normal {
          font-size: 16px;
          margin-top: 40px;
          .title {
            font-size: 20px;
            margin-bottom: 10px;
          }
          .click_info {
            display: block;
            margin-bottom: 40px;
            font-size: 12px;
            .brown_link {
              color: #9e7e6b;
              text-decoration: none;
              outline: 0;
            }
          }
          .tips {
            font-size: 12px;
          }
        }
        .uploaded {
          margin-top: 20px;
          .title {
            display: inline-block;
            font-size: 14px;
          }
          .click_info {
            display: inline-block;
            width: 60px;
            font-size: 14px;
            .brown_link {
              color: #9e7e6b;
              text-decoration: none;
              outline: 0;
            }
          }
          .upload_text {
            text-align: center;
            color: #999;
            font-size: 12px;
          }
        }
      }
      .fullscreen {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.3);
        z-index: 10;
        .fullscreen_text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 16px;
          color: #fff;
          font-weight: 700;
        }
      }
    }
    .box_bottom {
      height: 68px;
      background: #f5f5f5;
      border-top: 1px solid #f2f2f2;
      position: relative;
      .buttons {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .close_btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 50px;
      cursor: pointer;
      i {
        width: 30px;
        height: 30px;
        font-size: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

/* 添加穿透属性 */
.pointer_events {
  pointer-events: none;
}
</style>