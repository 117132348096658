<template>
  <div id="app">
    <herder
      v-if="mobileStatus && (!$route.path.includes('Admin') && $route.path != '/login')"
      @getInfo="getInfo"
    ></herder>
    <div v-if="mobileStatus && !$route.path.includes('Admin')" id="top"></div>
    <router-view
      :searchValue="searchValue"
      :style="$route.meta.topBar ? 'margin-top:70px' : ''"
      :key="key"
    />
    <mp-top v-if="$route.meta.topBar" :value="$route.meta.topBar"></mp-top>
    <side v-if="mobileStatus && !$route.path.includes('Admin')"></side>
    <mp-footer v-if="$route.meta.footerBar"></mp-footer>
    <mp-side v-if="$route.path != '/mLogin' && !mobileStatus"></mp-side>
  </div>
</template>

<script>
import herder from "@/components/top.vue";
import side from "@/components/side.vue";
import mpTop from "@/mp/components/mp-top.vue";
import mpFooter from "@/mp/components/mp-footer.vue";
import mpSide from "@/mp/components/mp-side.vue";
import Watermark from "./watermark";
export default {
  data() {
    return {
      searchValue: { id: "", content: "" },
      mobileStatus: true,
      showWatermark: false
    };
  },
  components: {
    herder,
    side,
    mpTop,
    mpFooter,
    mpSide
  },
  computed: {
    key() {
      return this.$route.fullPath;
    }
  },
  mounted() {
    if (this._isMobile()) {
      this.mobileStatus = false;
    } else {
      this.mobileStatus = true;
    }

    this.setWatermark();
  },
  activated() {},
  updated() {
    if (!this.showWatermark) {
      this.setWatermark();
    }
  },
  methods: {
    // 判断设备类型
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getInfo(data) {
      this.searchValue = data;
    },
    setWatermark() {
      let userData = localStorage.getItem("user_name");
      let token = localStorage.getItem("user_token");
      if (userData) {
        this.showWatermark = true;
        let time = this.formatDate();
        Watermark.set(userData + ' ' + time);
      } else {
        if (token) {
          this.$axios({
            url: this.$api + "/auth/user/info",
            mounted: "GET",
            headers: {
              Authorization: token,
              "Content-Type": "application/json;charset=UTF-8;"
            }
          }).then(res => {
            if (res.data.code == 200) {
              localStorage.setItem("user_name", res.data.data.username);
              this.showWatermark = true;
              let time = this.formatDate();
              Watermark.set(res.data.data.username + ' ' + time);
            } else {
              this.showWatermark = false;
              Watermark.set("");
            }
          });
        } else {
          this.showWatermark = false;
          Watermark.set("");
        }
      }
    },

    padDate(value) {
      return value < 10 ? "0" + value : value;
    },
    formatDate(value) {
      var date = new Date();
      if (value) {
        date = new Date(value);
      }

      var year = date.getFullYear(); // 获取年份
      var month = this.padDate(date.getMonth() + 1); // js里面月份(0~11月)会比正常的少1; 使用padDate函数转换小于10的月份
      var day = this.padDate(date.getDate());
      var hours = this.padDate(date.getHours());
      var minutes = this.padDate(date.getMinutes());
      var seconds = this.padDate(date.getSeconds());
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    }
  }
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
#top {
  height: 70px;
}
#app {
  background-color: #f4f4f4;
}
article,
aside,
blockquote,
body,
button,
code,
dd,
details,
div,
dl,
dt,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
input,
legend,
li,
menu,
nav,
ol,
p,
pre,
section,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
</style>
