<template>
  <div class="herder">
    <!-- 左侧图标 -->
    <div class="herder_left">
      <div class="herder_left_icon">
        <img src="../../public/icon.png" />
      </div>
      <!-- <div>活动方案内部分享平台</div> -->
    </div>
    <!-- 中间分栏 -->
    <div class="herder_content">
      <div @click="goPage('/')">首页</div>
      <div @click="goPage('/Portfolio')">作品库</div>
      <div @click="goPage('/collectHome')">站内素材</div>
      <div @click="goPage('/collectDiscovery')">站外素材</div>
    </div>
    <!-- 右侧信息头像 -->
    <div class="herder_right">
      <div class="herder_right_body" @click.stop>
        <div
          :class="
            unfoldStatus == false
              ? 'herder_right_input'
              : 'herder_right_input herder_right_inputOne'
          "
        >
          <div class="herder_right_inputSelect">
            <div
              v-if="delayStatus"
              class="herder_right_inputLeft"
              @click="unfoldDownStatus = !unfoldDownStatus"
            >
              <div>{{ selectInfo.name }}</div>
              <div
                :class="
                  unfoldDownStatus == false
                    ? 'herder_right_icon'
                    : 'herder_right_icon herder_right_icons'
                "
              >
                <i class="el-icon-arrow-down"></i>
              </div>
            </div>
            <div
              :class="
                unfoldDownStatus == false
                  ? 'herder_right_inputModel'
                  : 'herder_right_inputModel herder_right_inputModels'
              "
            >
              <div
                v-for="(item, index) in selectData"
                :key="index"
                @click="selectContent(index)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div v-if="delayStatus" class="herder_right_inputConter">
            <input type="text" v-model="value" @keyup.enter="searchContent()" />
            <div
              class="herder_right_inputLeftClear"
              v-if="value"
              @click="clearVal()"
            >
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div
            :class="
              unfoldStatus == false
                ? 'herder_right_inputRight'
                : 'herder_right_inputRight herder_right_inputRights'
            "
            @click="searchContent()"
          >
            <i class="el-icon-search"></i>
          </div>
        </div>
      </div>
      <!-- <div>消息</div> -->
      <div class="herder_right_avatar" @click="openUser()">
        <img :src="userPic" />
      </div>
      <div v-if="isAdmin" class="administrator" @click="goAdmin()">
        管理员入口
      </div>
      <div @click="loginOut">退出</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      // 栏目选择
      selectData: [
        { name: "方案标题", id: 1 },
        { name: "作者名称", id: 2 },
      ],
      selectInfo: {
        name: "方案标题",
        id: 1,
      },
      // 点击展开状态
      unfoldStatus: false,
      // 向下展开状态
      unfoldDownStatus: false,
      // 延迟状态
      delayStatus: false,
      // 用户头像
      userPic: '',
      // 用户权限
      isAdmin: '',
      // 人员管理权限
      isRoot: "",
    };
  },
  mounted() {
    this.getUserInfo();
    this.globalClick(this.shutStatus);
  },
  methods: {
    // 搜索选项
    selectContent(index) {
      this.selectInfo = this.selectData[index];
      this.unfoldDownStatus = false;
    },
    // 清除输入框
    clearVal() {
      this.value = "";
      let data = new Object();
      data.id = this.selectInfo.id;
      data.content = this.value;
      this.$emit("getInfo", data);
    },
    // 全局点击
    shutStatus() {
      this.unfoldStatus = false;
      this.unfoldDownStatus = false;
      this.delayStatus = false;
    },
    // 搜索内容
    searchContent() {
      //延时加载动画
      if (this.value == "") {
        this.unfoldStatus = !this.unfoldStatus;
        if (this.unfoldStatus) {
          setTimeout(() => {
            if (this.unfoldStatus) {
              this.delayStatus = true;
            } else {
              this.delayStatus = false;
            }
          }, 500);
        } else {
          this.delayStatus = false;
        }
        this.clearVal();
      } else {
        if (this.unfoldStatus) {
          // 进行搜索
          let data = new Object();
          data.id = this.selectInfo.id;
          data.content = this.value;
          // 对搜索进行判断，如果不在规定路由内搜索则需要进行Promise异步操作
          if (this.$route.path != "/Portfolio") {
            new Promise((resolve, reject) => {
              this.goPage("/Portfolio");
              resolve("跳转成功");
            }).then((res) => {
              this.$emit("getInfo", data);
            });
          } else {
            this.$emit("getInfo", data);
          }
        } else {
          // 这里是为了防止用户点击全局改变状态后输入框有值不能触发if的判断所做的补充
          this.unfoldStatus = true;
          setTimeout(() => {
            if (this.unfoldStatus) {
              this.delayStatus = true;
            } else {
              this.delayStatus = false;
            }
          }, 500);
        }
      }
    },
    // 跳转页面
    goPage(page) {
      this.$router.push({
        path: page,
      });
    },
    // 打开个人中心
    openUser() {
      this.$router.push({
        path: "/Creation",
      });
    },
    // 获取个人信息
    getUserInfo() {
      this.$axios({
        url: this.$api + "/auth/user/info",
        mounted: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if(res.data.code == 200){
          
          this.userPic = res.data.data.pic;
          this.isRoot = res.data.data.isRoot;
          this.isAdmin = res.data.data.isAdmin;
          localStorage.setItem("user_name", res.data.data.username);
        }
      })
    },
    // 打开管理员界面
    goAdmin(){
      this.$router.push({
        path: '/Admin',
        query:{
          isRoot: this.isRoot,
        }
      })
    },
    loginOut() {
      localStorage.removeItem('user_token');
      localStorage.removeItem('user_name');
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.herder {
  display: flex;
  justify-content: space-between;
  background-color: #fdd30f;
  color: #ffffff;
  height: 70px;
  padding: 0 30px;
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1200px;
  box-sizing: border-box;
  z-index: 1500;
}
.herder > div {
  width: 33%;
}
.herder_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.herder_left > div {
  margin: 20px;
}
.herder_left_icon > img {
  /* width: 2.5em; */
  height: 2.5em;
}
.herder_content {
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.herder_content > div:hover {
  background-color: #fff;
  color: #606266;
}
.herder_content > div {
  padding: 0 20px;
  line-height: 70px;
  cursor: pointer;
}
.herder_right > div:nth-of-type(1) {
  margin: 0 30px;
  box-sizing: border-box;
}
.herder_right > div:nth-of-type(2) {
  width: 70px;
  cursor: default;
  white-space: nowrap;
}
.herder_right > div:nth-of-type(3) {
  width: 70px;
  cursor: default;
  white-space: nowrap;
  cursor: pointer;
}
.herder_right > div:nth-of-type(4) {
  /* width: 70px; */
  cursor: default;
  white-space: nowrap;
}
.herder_right_body {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #000;
  width: 356px;
  position: relative;
}
.herder_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}
.herder_right_input {
  display: flex;
  justify-content: space-between;
  height: 35px;
  border-radius: 30px;
  box-sizing: border-box;
  background-color: #fff;
  width: 35px;
  overflow: hidden;
  transition: width 0.5s;
}
.herder_right_inputOne {
  width: 100%;
}
.herder_right_inputOne .herder_right_inputRight {
  width: 35px;
}
.herder_right_inputConter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}
.herder_right_input input {
  outline: none;
  border: none;
  width: 100%;
  font-size: 14px;
}
.herder_right_inputSelect {
  display: flex;
  align-items: center;
  justify-content: center;
}
.herder_right_inputSelect .herder_right_inputModels {
  height: 250%;
  background-color: #fff;
  border: 1px solid #e3e3e3;
}
.herder_right_inputSelect .herder_right_icons {
  transform: rotate(180deg);
}
.herder_right_icon {
  transition: transform 0.5s;
}
.herder_right_inputLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
  width: 70px;
}
.herder_right_inputLeftClear {
  color: #000;
  background-color: #b2b2b2;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.herder_right_inputs {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
}
.herder_right_inputRight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105%;
  font-size: 18px;
  font-weight: 600;
  line-height: 35px;
  color: #000;
  border-left: 1px solid #e3e3e3;
}
.herder_right_avatar > img {
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
}
.herder_right_inputModel {
  position: absolute;
  top: 35px;
  left: -5px;
  background-color: #ffffff00;
  height: 0%;
  overflow: hidden;
  border: 1px solid #e3e3e300;
  border-radius: 5px;
  z-index: 1501;
  transition: height 0.5s, background-color 0.5s, border 0.5s;
}
.herder_right_inputModel > div {
  margin: 10px;
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #e3e3e3;
}
.herder_right_inputModel > div:hover {
  background-color: #8a8a8a;
  color: #fff;
}

/* 管理员 */
.administrator {
  margin: 0 10px;
  box-sizing: border-box;
}
</style>