<template>
  <div class="side">
    <div class="side_item" @click="goTop()">
      <i class="el-icon-arrow-up"></i>
      <div>TOP</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goTop() {
      document.getElementById("app").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.side {
  position: fixed;
  bottom: 10%;
  right: 5%;
  z-index: 10;
}
.side_item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: #00000036;
}
.side_item > div {
  font-size: 10px;
}
.side_item > i {
  font-size: 20px;
}
</style>