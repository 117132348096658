<template>
  <div class="footer">
    <div
      @click="tabSelect(item.path)"
      v-for="(item,index) in tab"
      :key="index"
      :style="$route.path == item.path ? 'color:#000' : ''"
    >
      <el-badge :value="item.num">
        <div>
          <i :class="item.icon"></i>
        </div>
        <div class="text">{{item.name}}</div>
      </el-badge>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        {
          icon: "el-icon-s-home",
          name: "方案",
          num: '',
          path: '/mHome'
        },
        {
          icon: "el-icon-menu",
          name: "专题",
          num: '',
          path: '/mTopic'
        },
        {
          icon: "iconfont iconV",
          name: "素材",
          num: '',
          path: '/mCollectHome'
        },
        {
          icon: "el-icon-s-custom",
          name: "个人",
          num: '',
          path: '/mUser'
        },
      ],
    };
  },
  mounted(){
    
  },
  methods: {
    tabSelect(path) {
      this.$router.push({
        path: path,
      })
    },
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1% 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fdd30f;
}
.footer > div {
  width: 33%;
  text-align: center;
  color: #fff;
}
.text {
  font-size: 14px;
}
i {
  font-size: 24px;
}
</style>