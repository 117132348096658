import Vue from "vue";
import axios from "axios";
import VueRouter from "vue-router";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home/Home.vue"),
  },
  {
    path: "/HomeDetails",
    name: "HomeDetails",
    component: () => import("@/views/HomeDetails/HomeDetails.vue"),
  },
  {
    path: "/Creation",
    name: "Creation",
    component: () => import("@/views/Creation/Creation.vue"),
  },
  {
    path: "/topicList",
    name: "topicList",
    component: () => import("@/views/topicList/topicList.vue"),
  },
  {
    path: "/Portfolio",
    name: "Portfolio",
    component: () => import("@/views/Portfolio/Portfolio.vue"),
  },
  {
    path: "/topicDetails",
    name: "topicDetails",
    component: () => import("@/views/topicDetails/topicDetails.vue"),
  },
  {
    path: "/collectHome",
    name: "collectHome",
    component: () => import("../views/CollectHome/index.vue"),
  },
  {
    path: "/collectDiscovery",
    name: "collectDiscovery",
    component: () => import("../views/CollectDiscovery/index.vue"),
  },
  {
    path: "/collectDetails",
    name: "collectDetails",
    component: () => import("../views/CollectDetails/index.vue"),
  },
  {
    path: "/collectBoard",
    name: "collectBoard",
    component: () => import("../views/CollectBoard/index.vue"),
  },
  {
    path: "/collectSearch",
    name: "collectSearch",
    component: () => import("../views/CollectSearch/index.vue"),
  },
  {
    path: "/collectUser",
    name: "collectUser",
    component: () => import("../views/CollectUser/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login/login.vue"),
  },

  // 移动端路由
  // 主页
  {
    path: "/mHome",
    name: "m-home",
    meta: {
      footerBar: true,
    },
    component: () => import("@/mp/pages/mHome/mHome.vue"),
  },
  // 专题
  {
    path: "/mTopic",
    name: "m-topic",
    meta: {
      footerBar: true,
    },
    component: () => import("@/mp/pages/mTopic/mTopic.vue"),
  },
  // 专题详情
  {
    path: "/mTopicDetails",
    name: "m-topicDetails",
    meta: {
      topBar: "专题详情",
    },
    component: () => import("@/mp/pages/mTopicDetails/mTopicDetails.vue"),
  },
  // 方案详情
  {
    path: "/mHomeDetails",
    name: "m-homeDetails",
    meta: {
      topBar: "方案详情",
    },
    component: () => import("@/mp/pages/mHomeDetails/mHomeDetails.vue"),
  },
  // 个人中心
  {
    path: "/mUser",
    name: "m-user",
    meta: {
      footerBar: true,
    },
    component: () => import("@/mp/pages/mUser/mUser.vue"),
  },
  // 登陆
  {
    path: "/mLogin",
    name: "m-login",
    component: () => import("@/mp/pages/mLogin/mLogin.vue"),
  },
  // 我的作品
  {
    path: "/mMyWorks",
    name: "m-my-works",
    meta: {
      topBar: "我的作品",
    },
    component: () => import("@/mp/pages/mMyWorks/mMyWorks.vue"),
  },
  // 我的收藏
  {
    path: "/mMyKeep",
    name: "m-my-keep",
    meta: {
      topBar: "我的收藏",
    },
    component: () => import("@/mp/pages/mMyKeep/mMyKeep.vue"),
  },
  // 我的专题
  {
    path: "/mMyTopic",
    name: "m-my-topic",
    meta: {
      topBar: "我的专题",
    },
    component: () => import("@/mp/pages/mMyTopic/mMyTopic.vue"),
  },
  {
    path: "/mCollectHome",
    name: "m-collect-home",
    meta: {
      footerBar: true,
    },
    component: () => import("@/mp/pages/mp-collect/index.vue"),
  },
  {
    path: "/mCollectDetails",
    name: "m-collect-details",
    meta: {
      topBar: "采集详情",
    },
    component: () => import("@/mp/pages/mp-collect/details.vue"),
  },
  {
    path: "/mCollectBoard",
    name: "m-collect-board",
    meta: {
      topBar: "画板详情",
    },
    component: () => import("@/mp/pages/mp-collect/board.vue"),
  },
  {
    path: "/mCollectUser",
    name: "m-collect-user",
    meta: {
      topBar: "用户采集",
    },
    component: () => import("@/mp/pages/mp-collect/user.vue"),
  },
  {
    path: "/mCollectSearch",
    name: "m-collect-search",
    meta: {
      topBar: "搜索",
    },
    component: () => import("@/mp/pages/mp-collect/search.vue"),
  },
  // 超级管理员
  {
    path: "/Admin",
    // redirect:'/Admin/Carousel',
    component: () => import("@/views/Admin/index.vue"),
    children: [
      {// 轮播
        path: "",
        name: "Carousel",
        component: () => import("@/views/Admin/components/Carousel.vue"),
      },
      {// 分类
        path: "Classification",
        name: "Classification",
        component: () => import("@/views/Admin/components/Classification.vue"),
      },
      {// 爬虫
        path: "Crawler",
        name: "Crawler",
        component: () => import("@/views/Admin/components/Crawler.vue"),
      },
      {// 素材
        path: "Material",
        name: "Material",
        component: () => import("@/views/Admin/components/Material.vue"),
      },
      {// 方案
        path: "Program",
        name: "Program",
        component: () => import("@/views/Admin/components/Program.vue"),
      },
      {// 专题
        path: "Topic",
        name: "Topic",
        component: () => import("@/views/Admin/components/Topic.vue"),
      },
      {// 人员
        path: "Personnel",
        name: "Personnel",
        component: () => import("@/views/Admin/components/Personnel.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // 路由跳转后回到顶部
  // chrome
  document.body.scrollTop = 0;

  // firefox
  document.documentElement.scrollTop = 0;

  // safari
  window.pageYOffset = 0;

  if (to.name !== "login" && to.name !== "m-login") {
    console.log(1);
    const token = localStorage.getItem("user_token");
    if (token) {
      console.log(2);
      axios({
        url: "https://ppt.centurysouth.cn/auth/user/info",
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          console.log(3);
          if (to.fullPath == "/home") {
            let flag = navigator.userAgent.match(
              /(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            if (flag) {
              next("/mHome");
            } else {
              next();
            }
          } else {
            next();
          }
        } else {
          console.log(4);
          Message({
            message: res.data.msg,
            type: "error",
          });
          localStorage.removeItem("user_token");
          // 判断设备终端
          let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          );
          if (flag) {
            console.log(6);
            next("/mLogin");
          } else {
            console.log(7);
            next("/login");
          }
        }
      });
    } else {
      console.log(5);
      // 判断设备终端
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        console.log(6);
        next("/mLogin");
      } else {
        console.log(7);
        next("/login");
      }
    }
  } else {
    console.log(8);
    next();
  }
});

export default router;
