import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios';
import service from './api/index.js';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import waterfall from 'vue-waterfall2';
import 'default-passive-events';


// 导入全局样式表
import './assets/css/global.css'

//引入iconfont
import './assets/fonts/newiconfont/iconfont.css'
import './assets/fonts/iconfont/iconfont.css'

Vue.use(ElementUI);
Vue.use(waterfall);

Vue.prototype.$axios = axios;
Vue.prototype.$api = 'https://ppt.centurysouth.cn';
Vue.config.productionTip = false;
// 定义全局点击函数
Vue.prototype.globalClick = (callback) => {
    document.getElementById("app").onclick = () => {
        callback();
    };
};

Vue.prototype.$service = service;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");