<template>
  <div class="side">
    <div
      class="side_item"
      v-for="(item, index) in list"
      :key="index"
      @click="goTop(item.id)"
    >
      <div class="side_item_icon">
        <i :class="item.icon"></i>
      </div>
      <div class="side_item_name">{{ item.name }}</div>
    </div>

    <upload-collect :visible.sync="visible"></upload-collect>

    <div class="dialog_boxes" v-if="boardVisible">
      <div class="board_dialog">
        <div class="dialog_title">
          新建画板
          <i class="el-icon-close" @click.stop="boardVisible = false"></i>
        </div>
        <div class="dialog_form">
          <el-input placeholder="画板标题" v-model="boardTitle" clearable></el-input>
          <el-select v-model="boardCat" filterable placeholder="选择分类">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="btns">
          <el-button type="info" plain @click.stop="boardVisible = false">取消</el-button>
          <el-button type="danger" :icon="submitting ? 'el-icon-loading' : ''" :disabled="submitting" @click="submitBoard">
            {{submitting ? '画板提交中' : '创建画板'}}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadCollect from "@/components/UploadCollect";
export default {
  name: "side",
  components: {
    UploadCollect,
  },
  data() {
    return {
      list: [
        { icon: "el-icon-upload", name: "上传方案", id: "/Creation" },
        { icon: "el-icon-upload2", name: "上传素材", id: "#upload" },
        { icon: "el-icon-collection", name: "添加画板", id: "#board" },
        { icon: "el-icon-arrow-up", name: "TOP", id: "#top" },
      ],
      visible: false,
      boardVisible: false,
      boardTitle: '',
      boardCat: '',
      options: [{
          value: '选项1',
          label: 'UI/UX'
        }, {
          value: '选项2',
          label: '摄影'
        }, {
          value: '选项3',
          label: '建筑设计'
        }, {
          value: '选项4',
          label: '人文艺术'
        }, {
          value: '选项5',
          label: '手工/布艺'
      }],
      submitting: false,
    };
  },
  methods: {
    goTop(id) {
      if (id == "#top") {
        document.getElementById("top").scrollIntoView({
          behavior: "smooth",
        });
      } else if (id == "#upload") {
        this.visible = true;
      } else if (id == "#board") {
        this.boardVisible = true;
      } else {
        let url = this.$router.history.current.path == "/Creation";
        if (url) {
        } else {
          this.$router.push({
            path: "/Creation",
            query: {
              tabId: 2,
            },
          });
        }
      }
    },
    submitBoard() {
      this.$message('接口开发中');
      return false;
      this.submitting = true;
      setTimeout(() => {
        this.submitting = false;
      }, 3000)
    },
  },
};
</script>

<style scoped>
.side {
  position: fixed;
  right: 60px;
  bottom: 20px;
  width: 70px;
  height: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 1500;
}
.side_item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  transition: background-color 0.5s;
}
.side_item:nth-child(2) {
  padding: 10px 0;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
}
.side_item:hover {
  background-color: #fdd30f;
  color: #fff;
}
.side_item_icon > i {
  font-size: 1rem;
}
.side_item_name {
  font-size: .5rem;
}
@media screen and (min-width: 2000px) and (max-width: 2500px) {
  .side_item_icon > i {
    font-size: 32px;
  }
  .side_item_name {
    font-size: 16px;
  }
  .side{
    width: 5vw;
  }
}
@media screen and (min-width: 4000px) and (max-width: 4500px) {
  .side_item_icon > i {
    font-size: 64px;
  }
  .side_item_name {
    font-size: 30px;
  }
  .side{
    width: 5vw;
  }
}
</style>

<style lang="scss" scoped>
.dialog_boxes {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.3);
  z-index: 1999;
  .board_dialog {
    width: 550px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 5px rgba(0,0,0,.3);
    border-radius: 1px;
    .dialog_title {
      font-size: 16px;
      border-bottom: 1px solid #ededed;
      position: relative;
      padding: 15px 30px;
      box-sizing: border-box;
      color: #222;
      margin-bottom: 20px;
      .el-icon-close {
        float: right;
        font-size: 30px;
        margin-top: -3px;
        color: #777;
        cursor: pointer;
      }
    }
    .dialog_form {
      padding: 0 30px;
      .el-select {
        margin-top: 20px;
        width: 100%;
      }
    }
    .btns {
      margin-top: 20px;
      border-top: 1px solid #ebebeb;
      padding: 10px 30px;
      text-align: center;
    }
  }
}
</style>