import axios from 'axios';
import Qs from 'qs';
import { Message } from 'element-ui';

const DEFAULT = axios.create({
  baseURL: 'https://ppt.centurysouth.cn',
  timeout: 1000 * 10, // 请求超时时间
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  },
  transformRequest: [
    function (data) {
      data = Qs.stringify(data);
      return data;
    }
  ]
})
DEFAULT.interceptors.request.use(
  config => {
    const token = localStorage.getItem('user_token');
    token && (config.headers.Authorization = token);
    return config;
  },
  error => Promise.reject(error)
)
DEFAULT.interceptors.response.use(
  res => {
    if (res.status === 200) {
      if (res.data.code === 200) {
        return Promise.resolve(res.data);
      } else if (res.data.code === 206) {
        localStorage.removeItem('user_token');
        if (document.getElementsByClassName('el-message').length == 0) {
          Message({
            message: '登录失效，请重新登录!',
            type: 'warning',
            duration: 2000,
            onClose() {
              window.location.reload();
            }
          });
        }
      } else {
        return Promise.resolve(res.data);
      }
    } else {
      localStorage.removeItem('user_token');
      window.location.reload();
    }
  },
  error => Promise.reject(error)
)

const JSON = axios.create({
  baseURL: 'https://ppt.centurysouth.cn',
  timeout: 1000 * 10, // 请求超时时间
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  }
})
JSON.interceptors.request.use(
  config => {
    const token = localStorage.getItem('user_token');
    token && (config.headers.Authorization = token);
    return config;
  },
  error => Promise.reject(error)
)
JSON.interceptors.response.use(
  res => {
    if (res.status === 200) {
      if (res.data.code === 200) {
        return Promise.resolve(res.data);
      } else if (res.data.code === 206) {
        localStorage.removeItem('user_token');
        if (document.getElementsByClassName('el-message').length == 0) {
          Message({
            message: '登录失效，请重新登录!',
            type: 'warning',
            duration: 2000,
            onClose() {
              window.location.reload();
            }
          });
        }
      } else {
        Message({
          message: res.data.msg,
          type: 'warning'
        });
        return Promise.resolve(res.data);
      }
    } else {
      localStorage.removeItem('user_token');
      window.location.reload();
    }
  },
  error => Promise.reject(error)
)

const UPLOAD = axios.create({
  baseURL: 'https://img.centurysouth.cn/group1/upload?output=json',
})
UPLOAD.interceptors.request.use(
  config => {
    return config
  },
  error => Promise.reject(error)
)
UPLOAD.interceptors.response.use(
  response => {
    return Promise.resolve(response.data)
  },
  error => Promise.reject(error)
)


const CORSRUEST = axios.create({
  baseURL: "https://ppt.centurysouth.cn",
  timeout: 5000,
})
CORSRUEST.interceptors.request.use(
  config => {
    return config
  },
  error => Promise.reject(error)
)
CORSRUEST.interceptors.response.use(
  response => {
    return Promise.resolve(response.data)
  },
  error => Promise.reject(error)
)

export default {
  DEFAULT,
  JSON,
  UPLOAD,
  CORSRUEST
};
